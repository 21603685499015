import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import Logo from '../assets/CircleLogo.png';
import on1 from '../assets/1on1.jpeg';
import noCage from '../assets/noCage.png';
import Qual from '../assets/Qual.png';
import '../styles/home.css';

const Home = () => {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <img src={Logo} alt="Logo" style={styles.logo} />
                <p style={styles.missionStatement}>
                    Welcome to Sugar Paws Pet Salon, where your pet's comfort comes first! 🐾
                    <br />
                    At Sugar Paws, we specialize in 1-on-1 grooming, ensuring that your pet receives our undivided attention from start to finish. Say goodbye to cages! Our cage-free environment is designed to provide a stress-free experience for your pet, making grooming a great experience for everyone!
                </p>
            </header>
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <Button
                    component={RouterLink}
                    to="/services"
                    sx={{
                        color: 'black',
                        border: '2px solid black',
                        width: '200px',
                        '&:hover': {
                            backgroundColor: '#599a89',
                            color: 'white',
                            border: '2px solid transparent',
                        },
                    }}
                >
                    Services
                </Button>
            </div>
            <Grid container spacing={3} justifyContent="center">
                {contentSections.map((section, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div style={styles.sectionWrapper}>
                            <h2 style={styles.sectionHeading}>{section.title}</h2>
                            <p>{section.description}</p>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <div style={styles.specialties}>
                <h2 style={styles.specialtiesHeading}>Specialties</h2>
                <ul style={styles.specialtiesList}>
                    {specialties.map((specialty, index) => (
                        <li key={index} style={styles.specialtyItem}>
                            {specialty}
                        </li>
                    ))}
                </ul>
            </div>
            <Grid container spacing={3} justifyContent="center" style={{ marginTop: '30px' }}>
                {images.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div style={styles.imageWrapper}>
                            <img src={image.src} alt={image.alt} style={styles.image} />
                            <h2 style={styles.imageHeading}>{image.title}</h2>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <footer style={styles.footer}>
                <strong>Best Of Dublin 2024</strong>
            </footer>
        </div>
    );
};

const contentSections = [
    {
        title: 'Care',
        description:
            'Where every pet gets the VIP treatment! We specialize in providing tailored, affectionate care. Your pet is a beloved member of your family, and we treat them as such.',
    },
    {
        title: 'Clean',
        description:
            'Our salon prides itself on impeccable hygiene, using high-end products for a great finish. Your pet’s health and safety are our top priorities!',
    },
    {
        title: 'Style',
        description:
            'Step into a world of personalized style at Sugar Paws Pet Salon! Grooming is an art form, and we showcase your pet’s unique personality through their style.',
    },
];

const specialties = [
    '1-on-1 Grooming Sessions',
    'Stress-Free, Cage-Free Environment',
    'High-Quality Products for Pet Grooming',
    'Personalized Grooming Styles',
    'Clean and Hygienic Facilities',
];

const images = [
    { src: on1, alt: '1 on 1 Grooming', title: '1 On 1 Grooming' },
    { src: noCage, alt: 'Cage Free', title: 'Cage Free' },
    { src: Qual, alt: 'High Quality Products', title: 'High Quality Products' },
];

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        color: 'black',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
        padding: '20px',
    },
    header: {
        marginBottom: '20px',
    },
    logo: {
        width: '100%',
        maxWidth: '400px',
        height: 'auto',
        marginBottom: '20px',
        borderRadius: '8px',
    },
    missionStatement: {
        fontSize: '18px',
        maxWidth: '800px',
        margin: '0 auto',
    },
    sectionWrapper: {
        backgroundColor: '#6b4b21',
        padding: '20px',
        borderRadius: '8px',
        color: 'white',
    },
    sectionHeading: {
        fontSize: '24px',
        marginBottom: '10px',
    },
    specialties: {
        marginTop: '40px',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
    },
    specialtiesHeading: {
        fontSize: '24px',
        marginBottom: '15px',
        color: '#333',
    },
    specialtiesList: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    specialtyItem: {
        fontSize: '18px',
        marginBottom: '10px',
        color: '#555',
    },
    imageWrapper: {
        textAlign: 'center',
    },
    image: {
        width: '100%',
        maxWidth: '200px',
        height: 'auto',
        marginBottom: '10px',
    },
    imageHeading: {
        fontSize: '20px',
        marginTop: '10px',
    },
    footer: {
        marginTop: '40px',
        fontSize: '30px',
    },
};

export default Home;
