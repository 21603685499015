import React from 'react';
import { Grid, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import '../styles/service.css';

const Services = () => {
    const serviceData = [
        {
            title: 'Haircut',
            description: 'Includes: Bath, Blow Out, Brush Out, Ear Cleaning, Nail Buffing, Teeth Spray, Scent, Bow/Bandanna',
            prices: [
                { label: 'Small', price: '$120-$140+' },
                { label: 'Medium', price: '$140-$160+' },
                { label: 'Large', price: '$165-$200+' },
                { label: 'Extra Large', price: '$200-$220+' },
                { label: 'Standard Poodles/Doodles', price: '$220+' }
            ]
        },
        {
            title: 'Bath',
            description: 'Includes: Bath, Blow Out, Brush Out, Ear Cleaning, Nail Buffing, Teeth Spray, Scent, Bow/Bandanna',
            prices: [
                { label: 'Small', price: '$75-$85+' },
                { label: 'Medium', price: '$85-$100+' },
                { label: 'Large', price: '$100-$120+' },
                { label: 'Extra Large', price: '$120-$140+' },
                { label: 'Cat Baths', price: '$135-$160+' }
            ]
        },
        {
            title: 'A La Carte',
            prices: [
                { label: 'Nail Buffing', price: '$35-$55+' },
                { label: 'Anal Glands (External ONLY)', price: '$30' },
                { label: 'Teeth Brushing/Spray', price: '$15' },
                { label: 'Ear Cleaning', price: '$15' },
                { label: 'Ear Hair Plucking/Shave', price: '$20' },
                { label: 'Feet Trimming', price: '$20' },
                { label: 'Eye Trimming', price: '$15' },
                { label: 'Sanitary Trim', price: '$15' }
            ]
        },
        {
            title: 'Tidy Up',
            description: (
                <>
                    <p style={{ textAlign: 'left', marginBottom: '-10px', fontWeight: 'bold' }}>
                        Face, Feet and Sanitary Trim
                    </p>
                    <p style={{ textAlign: 'left', marginBottom: '25px' }}>
                        $20 Plus Bath Price
                    </p>
                    <p style={{ textAlign: 'left', marginBottom: '-10px', fontWeight: 'bold' }}>
                        Outline
                    </p>
                    <p style={{ textAlign: 'left', marginBottom: '30px' }}>
                        $35 Plus Bath Price
                    </p>
                    <p style={{ textAlign: 'left', marginBottom: '25px' }}>
                        Shape all over whatever is sticking out:
                        Legs, Feet, Sanitary, Face, and Undercarriage
                        (Length on body stays the same)
                    </p>
                </>
            ),
        },

        {
            title: 'Add Ons',
            prices: [
                { label: 'Medicated Bath', price: '$15' },
                { label: 'Flea Bath', price: '$15' },
                { label: 'Shedless Treatment', price: '$25-45+' }
            ],
            description: 'Add on with Haircut/Bath Packages'
        }
    ];

    return (
        <div style={styles.container}>
            <Grid container spacing={4} justifyContent="center">
                {serviceData.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div style={styles.itemWrapper}>
                            <div style={styles.textContent}>
                                <h2 style={styles.sectionHeading}>{service.title}</h2>
                                {service.description && <p style={styles.description}>{service.description}</p>}
                                {service.prices && service.prices.map((price, idx) => (
                                    <div className="price-section" key={idx} style={styles.priceSection}>
                                        <p>{price.label}:</p>
                                        <p>{price.price}</p>
                                    </div>
                                ))}
                                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                    <Button
                                        component={RouterLink}
                                        to="/booking"
                                        sx={{
                                            color: 'black',
                                            border: '2px solid black',
                                            width: '60%',
                                            '&:hover': {
                                                backgroundColor: '#599a89',
                                                color: 'white',
                                                border: '2px solid transparent',
                                            },
                                        }}
                                    >
                                        Book Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        textAlign: 'left',
    },
    itemWrapper: {
        maxWidth: '320px',
        width: '100%',
        margin: '0 auto',
    },
    textContent: {
        width: '100%',
        boxSizing: 'border-box',
        border: '3px solid #599a89',
        padding: '20px',
        borderRadius: '8px',
        color: 'black',
        backgroundColor: '#f9f9f9',
    },
    sectionHeading: {
        color: 'black',
        textAlign: 'center',
        fontSize: '24px',
        marginBottom: '10px',
    },
    description: {
        textAlign: 'left',
        marginBottom: '15px',
    },
    priceSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px',
    },
};

export default Services;