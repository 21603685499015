import React from 'react';
import Logo from '../assets/CircleLogo.png'
import BookingW from '../components/BookingW';

const Booking = () => {
    return (
        <div style={styles.container}>
            <BookingW />
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        marginTop: '0px',
    },
    logo: {
        maxWidth: '250px',
        marginBottom: '20px',
    },
    header: {
        fontSize: '40px',
        marginBottom: '10px',
    },
    text: {
        fontSize: '18px',
        marginBottom: '10px',
    },
    email: {
        fontSize: '20px',
        color: '#599a89',
        textDecoration: 'none',
    },
};

export default Booking;
