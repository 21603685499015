import React from 'react';
import Logo from '../assets/CircleLogo.png';

const address = '6982 Village Parkway, Dublin CA 94568';

const openLocationInMaps = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(mapsUrl, '_blank');
};

const Booking = () => {
    return (
        <div style={styles.container}>
            <img src={Logo} alt="Logo" style={styles.logo} />



            <section style={styles.section}>
                <h1 style={styles.header}>Hours</h1>
                <div style={styles.hoursContainer}>
                    {[
                        { day: 'Monday', hours: 'Closed' },
                        { day: 'Tuesday', hours: 'By Appointment Only' },
                        { day: 'Wednesday', hours: 'By Appointment Only' },
                        { day: 'Thursday', hours: 'By Appointment Only' },
                        { day: 'Friday', hours: 'By Appointment Only' },
                        { day: 'Saturday', hours: 'By Appointment Only' },
                        { day: 'Sunday', hours: 'Closed' },
                    ].map((entry, index) => (
                        <div key={index} style={styles.hoursRow}>
                            <p style={styles.day}>{entry.day}</p>
                            <p style={styles.time}>{entry.hours}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section style={styles.section}>
                <h1 style={styles.header}>Location</h1>
                <a onClick={openLocationInMaps} style={styles.link}>{address}</a>
            </section>

            <section style={styles.section}>
                <h1 style={styles.header}>Contact</h1>
                <p style={styles.text}>The best way to reach us is by <b>TEXT</b></p>
                <a href="tel:5109092375" style={styles.link}>+1 (510) 909-2375</a>
                <a href="mailto:SugarPaws@gmail.com" style={styles.link}>SugarPawsPetSalon@yahoo.com</a>
            </section>
        </div>
    );
};

const styles = {
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
        backgroundColor: '#f9f9f9',
        color: 'black',
        textAlign: 'center',
    },
    logo: {
        width: '150px',
        marginBottom: '30px',
    },
    section: {
        marginBottom: '40px',
        width: '100%',
        maxWidth: '600px',
    },
    header: {
        fontSize: '28px',
        marginBottom: '20px',
        borderBottom: '2px solid #ccc',
        paddingBottom: '10px',
    },
    text: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    link: {
        fontSize: '18px',
        color: '#007BFF',
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'block',
        marginBottom: '10px',
    },
    hoursContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    hoursRow: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
        borderBottom: '1px solid #eee',
        padding: '5px 0',
    },
    day: {
        fontWeight: 'bold',
    },
    time: {
        fontStyle: 'italic',
    },
};

export default Booking;
